var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "receipts" },
    [
      _c("h4", { staticClass: "text-uppercase" }, [
        _vm._v(_vm._s(_vm.$t("receipts.title")))
      ]),
      _vm._l(Object.keys(_vm.groupedData), function(section, index) {
        return _c(
          "v-list",
          { key: "section-" + index, staticClass: "pa-0", attrs: { flat: "" } },
          [
            _c("v-subheader", [_vm._v(_vm._s(section))]),
            _c(
              "v-list-item-group",
              { attrs: { color: "primary" } },
              _vm._l(_vm.groupedData[section], function(receipe, index) {
                return _c(
                  "v-list-item",
                  {
                    key: "receipe" + index,
                    attrs: { to: "/dashboard/receipts/" + receipe.index }
                  },
                  [
                    _c(
                      "v-list-item-icon",
                      [_c("v-icon", [_vm._v("$invoice")])],
                      1
                    ),
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-title", [
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm
                                    .$dayjs(receipe.dataSpesa, _vm.dateFormat)
                                    .format("DD MMMM")
                                ) +
                                " "
                            )
                          ]),
                          _c("div", { staticClass: "font-weight-semibold" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$n(receipe.lordo, "currency")) +
                                " "
                            )
                          ])
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-icon",
                      [_c("v-icon", [_vm._v("$chevronRight")])],
                      1
                    )
                  ],
                  1
                )
              }),
              1
            )
          ],
          1
        )
      }),
      _vm.receiptList.length < 1 && !_vm.loading
        ? _c(
            "div",
            {
              staticClass:
                "text-center font-weight-semibold secondary--text text-body-0 py-6"
            },
            [_vm._v(" " + _vm._s(_vm.noDataMessage) + " ")]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }