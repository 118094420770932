<template>
  <v-container class="receipts">
    <h4 class="text-uppercase">{{ $t("receipts.title") }}</h4>
    <v-list
      flat
      v-for="(section, index) in Object.keys(groupedData)"
      :key="'section-' + index"
      class="pa-0"
    >
      <v-subheader>{{ section }}</v-subheader>
      <v-list-item-group color="primary">
        <v-list-item
          v-for="(receipe, index) in groupedData[section]"
          :key="'receipe' + index"
          v-bind:to="'/dashboard/receipts/' + receipe.index"
        >
          <v-list-item-icon>
            <v-icon>$invoice</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              ><div>
                {{ $dayjs(receipe.dataSpesa, dateFormat).format("DD MMMM") }}
              </div>
              <div class="font-weight-semibold">
                {{ $n(receipe.lordo, "currency") }}
              </div>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon>$chevronRight</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <div
      v-if="receiptList.length < 1 && !loading"
      class="text-center font-weight-semibold secondary--text text-body-0 py-6"
    >
      {{ noDataMessage }}
    </div>
  </v-container>
</template>
<style lang="scss">
.receipts {
  .v-subheader {
    background-color: #f1f1f1;
    padding: 0 5px;
    text-transform: capitalize;
    font-weight: 600;
    color: var(--v-default-base);
  }
  .v-list-item {
    padding: 0 5px;
    .v-list-item__title {
      font-size: 16px;
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
<script>
import CustomService from "@/service/customService";
import categoryMixins from "~/mixins/category";
import get from "lodash/get";

export default {
  name: "Receipts",
  mixins: [categoryMixins],
  data() {
    return {
      receiptList: [],
      pdfsrc: null,
      dateFormat: "YYYYMMDDHHmmss",
      loading: false
    };
  },
  computed: {
    groupedData() {
      const grouped = {};
      for (const item of this.receiptList) {
        const monthYear = this.$dayjs(item.dataSpesa, this.dateFormat).format(
          "MMMM YYYY"
        );
        if (!grouped[monthYear]) {
          grouped[monthYear] = [];
        }
        grouped[monthYear].push(item);
      }
      return grouped;
    },
    noDataMessage() {
      return get(
        this.category,
        "metaData.category_info.NO_DATA_TEXT",
        this.$t("message.noData")
      );
    }
  },
  methods: {
    async reload() {
      this.loading = true;
      await CustomService.getReceiptList()
        .then(data => {
          if (data.values) {
            this.receiptList = data.values;
          } else {
            this.receiptList = [];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  async mounted() {
    await this.reload();
  }
};
</script>
